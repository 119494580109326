<template>
  <div>
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <h1>CTT Expresso</h1>
    <div class="container">
      <h3>Lista de Envios Pendentes</h3>
      <br />
      <h6><b>Encomendas Pendentes: </b> {{ cttExpressoShipments.length }}</h6>
      <select name="service_ctte" v-model="subProduct" @change="filterCTTExpresso">
        <option value="EMSF056.01">CTT Expresso PA (EMSF056.01)</option>
        <option value="EMSF057.01">CTT Expresso 48h (EMSF057.01)</option>
        <option value="EMSF001.02">CTT Expresso EMS (EMSF001.02)</option>
        <option value="ENCF008.02">CTT Expresso EMS Economy (ENCF008.02)</option>
      </select>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">DATA</th>
            <th scope="col">Transportadora</th>
            <th scope="col">Encomenda</th>
            <th scope="col">Nº Envio</th>
            <th scope="col">Confirmar</th>
            <th scope="col">Cancelar</th>
          </tr>
        </thead>
        <tbody v-for="shipment in filterCTTExpressoType" :key="shipment.id">
          <td>{{ shipment.date }}</td>
          <td>{{ shipment.shipping_company }}</td>
          <td>{{ shipment.order_id }}</td>
          <td>
            <label :for="shipment.id">{{ shipment.tracking_number }}</label>
          </td>
          <td>
            <input
              type="checkbox"
              :id="shipment.id"
              :value="{
                id: shipment.id,
                tracking: shipment.tracking_number,
                type: shipment.type_ctte,
              }"
              v-model="checked"
            />
          </td>
          <td @click="deleteShipment(shipment.id, shipment.tracking_number)">
            ❌
          </td>
        </tbody>
      </table>
    </div>
    <h6><b>Total Encomendas: </b> {{ checked.length }}</h6>

    <div>
      <button
        class="btn btn-primary"
        @click="closeCttExpresso()"
        :disabled="checked.length == 0"
      >
        Fechar Guia CTTExpresso
      </button>
    </div>
    <br />
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Transportadora</th>
          <th scope="col">Data</th>
          <th scope="col">Ficheiro</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="close in allShipments" :key="close.id">
          <td>{{ close.shipping_company }}</td>
          <td>{{ calculateDate(close.date) }}</td>
          <td>
            <button class="btn btn-primary" @click="openFile(close.file)">
              Ver Ficheiro
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Loading from "@/components/Loading.vue";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      allShipments: [],
      loading: false,
      cttExpressoShipments: [],
      checked: [],
      subProduct: "EMSF056.01",
      filterCTTExpressoType: [],
    };
  },
  methods: {
    async closeCttExpresso() {
      this.loading = true;
      try {
        await this.$store.dispatch("closeShipmentCTTExpresso", {
          shipments: this.checked,
        });
        this.getAllCTTClose;
      } catch (err) {
        alert(err);
      }
      this.loading = false;
    },
    async filterCTTExpresso() {
      this.loading = true;
      this.checked = [];
      try {
        await this.$store.dispatch("filterCttExpressoShipments", {
          type: this.subProduct,
        });
        this.filterCTTExpressoType = this.getAllCTTClose;
      } catch (err) {
        alert(err);
      }
      this.loading = false;
    },
    async getAllClose() {
      this.loading = true;
      try {
        await this.$store.dispatch("allCloseShipment");
        console.log(this.getAllCTTClose);
        this.allShipments = this.getAllCTTClose;
      } catch (err) {
        alert(err);
      } 
      this.loading = false;
    },
    openFile(file) {
      window.open("data:application/pdf;base64," + file, "_blank");
    },
    calculateDate(date) {
      let newDate = new Date(date.split("T"));
      let year = newDate.getFullYear();
      let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
      let day = ("0" + newDate.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    },
    async allCTTExpressoShipments() {
      await this.$store.dispatch("allShipmentsCTTExpresso");
      this.cttExpressoShipments = this.getAllCTTExpressoShipments;
    },
    async deleteShipment(id, shipment) {
      this.$swal({
        title: "Quer eliminar o envio " + shipment + " ?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, eliminar!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading = true;
          try {
            await this.$store.dispatch("cttExpressoDeleteShipment", {
              id: id,
            });
            await this.allCTTExpressoShipments();
            this.$swal("Completado", "Envio Eliminado!", "success");
          } catch (err) {
            alert(err);
          } 
          this.loading = false;
        }
      });
    },
  },
  async created() {
    this.loading = true;
    await this.allCTTExpressoShipments();
    await this.getAllClose();
    await this.filterCTTExpresso()
    this.loading = false;
  },
  computed: {
    ...mapGetters(["getAllCTTClose"]),
    ...mapGetters(["getAllCTTExpressoShipments"]),
  },
};
</script>
<style scoped>
* {
  text-align: center;
}
.table {
  margin-top: 10px;
}
</style>
