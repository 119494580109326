var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(this.loading == true)?_c('span',{attrs:{"id":"loading"}},[_c('Loading')],1):_vm._e(),_c('h1',[_vm._v("CTT Expresso")]),_c('div',{staticClass:"container"},[_c('h3',[_vm._v("Lista de Envios Pendentes")]),_c('br'),_c('h6',[_c('b',[_vm._v("Encomendas Pendentes: ")]),_vm._v(" "+_vm._s(_vm.cttExpressoShipments.length))]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.subProduct),expression:"subProduct"}],attrs:{"name":"service_ctte"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.subProduct=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.filterCTTExpresso]}},[_c('option',{attrs:{"value":"EMSF056.01"}},[_vm._v("CTT Expresso PA (EMSF056.01)")]),_c('option',{attrs:{"value":"EMSF057.01"}},[_vm._v("CTT Expresso 48h (EMSF057.01)")]),_c('option',{attrs:{"value":"EMSF001.02"}},[_vm._v("CTT Expresso EMS (EMSF001.02)")]),_c('option',{attrs:{"value":"ENCF008.02"}},[_vm._v("CTT Expresso EMS Economy (ENCF008.02)")])]),_c('table',{staticClass:"table"},[_vm._m(0),_vm._l((_vm.filterCTTExpressoType),function(shipment){return _c('tbody',{key:shipment.id},[_c('td',[_vm._v(_vm._s(shipment.date))]),_c('td',[_vm._v(_vm._s(shipment.shipping_company))]),_c('td',[_vm._v(_vm._s(shipment.order_id))]),_c('td',[_c('label',{attrs:{"for":shipment.id}},[_vm._v(_vm._s(shipment.tracking_number))])]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checked),expression:"checked"}],attrs:{"type":"checkbox","id":shipment.id},domProps:{"value":{
              id: shipment.id,
              tracking: shipment.tracking_number,
              type: shipment.type_ctte,
            },"checked":Array.isArray(_vm.checked)?_vm._i(_vm.checked,{
              id: shipment.id,
              tracking: shipment.tracking_number,
              type: shipment.type_ctte,
            })>-1:(_vm.checked)},on:{"change":function($event){var $$a=_vm.checked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v={
              id: shipment.id,
              tracking: shipment.tracking_number,
              type: shipment.type_ctte,
            },$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checked=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checked=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checked=$$c}}}})]),_c('td',{on:{"click":function($event){return _vm.deleteShipment(shipment.id, shipment.tracking_number)}}},[_vm._v(" ❌ ")])])})],2)]),_c('h6',[_c('b',[_vm._v("Total Encomendas: ")]),_vm._v(" "+_vm._s(_vm.checked.length))]),_c('div',[_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.checked.length == 0},on:{"click":function($event){return _vm.closeCttExpresso()}}},[_vm._v(" Fechar Guia CTTExpresso ")])]),_c('br'),_c('table',{staticClass:"table"},[_vm._m(1),_c('tbody',_vm._l((_vm.allShipments),function(close){return _c('tr',{key:close.id},[_c('td',[_vm._v(_vm._s(close.shipping_company))]),_c('td',[_vm._v(_vm._s(_vm.calculateDate(close.date)))]),_c('td',[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.openFile(close.file)}}},[_vm._v(" Ver Ficheiro ")])])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("DATA")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Transportadora")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Encomenda")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Nº Envio")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Confirmar")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Cancelar")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Transportadora")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Data")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Ficheiro")])])])
}]

export { render, staticRenderFns }